import React from 'react'
import classNames from 'classnames'
import { node, oneOf, string } from 'prop-types'
// import sassKeysToArray from '@/lib/sass-keys-to-array'

import styles from './Stack.module.scss'

import Element from '../Element'

const gaps = ['tiny', 'small', 'medium', 'large']
const Stack = ({ as, children, gap }) => (
  <Element as={as} className={classNames(styles.Stack, gap && styles[gap])}>
    {children}
  </Element>
)

Stack.defaultProps = {
  gap: 'medium'
}

Stack.propTypes = {
  as: string,
  children: node.isRequired,
  // gap: oneOf(sassKeysToArray(styles.gaps))
  gap: oneOf(gaps)
}

export default Stack
