import { request } from 'graphql-request'
import absoluteUrl from './absolute-url-determiner'
import wrapTranslations from '../../../components/service/instance/lib/translation-wrapper'

const retrieveData = async (context, query, args) => {
  const { origin } = absoluteUrl(context.req)
  const vars = { url: `${origin}${context.asPath}`, ...args }

  let data = null
  // If we are server side rendering, no point in going over HTTP, go directly to the graphql schema
  if (context.query.graphqlManager && context.query.graphqlManager.query) {
    const response = await context.query.graphqlManager.query(query, vars)
    if (response && response.errors && response.errors.length) {
      const error = new Error('Error making graphql request')
      error.errors = response.errors
      throw error
    }
    data = response.data
  } else if (typeof window !== 'undefined') {
    const graphqlUrl = `${window.location.origin}/graphql`
    data = await request(graphqlUrl, query, vars)
  }
  if (data && data.instance) {
    const { translations } = data.instance
    data.instance.translations = wrapTranslations(translations)
  }
  return { data, vars }
}

export default retrieveData
