import React from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './Type.module.scss'

import Element from '../Element'

export const sizes = [
  'heading1',
  'heading2',
  'heading3',
  'heading4',
  'heading5',
  'base',
  'small'
]

export const colors = ['grey40', 'purple', 'white', 'yellow']

export const weights = ['bold']

const Type = ({ as, children, className, color, size, tight, weight }) => {
  const colorOverride = color && colors.includes(color) ? null : color
  return (
    <Element
      as={as}
      className={classNames(
        styles.Type,
        size && styles[size],
        tight && styles.tight,
        color && styles[`color-${color}`],
        weight && styles[`weight-${weight}`],
        className
      )}
      style={{ color: colorOverride }}
    >
      {children}
    </Element>
  )
}

Type.displayName = 'Type'

Type.defaultProps = {
  as: 'div',
  size: 'base'
}

Type.propTypes = {
  as: string,
  children: node.isRequired,
  className: string,
  color: oneOf(colors),
  size: oneOf(sizes),
  tight: bool,
  weight: oneOf(weights)
}

export default Type
