import React from 'react'
import classNames from 'classnames'
import { bool, node, string } from 'prop-types'

import styles from './Prose.module.scss'

const Prose = ({ children, className, dangerousHtml, inverse, color }) => {
  if (!children && !dangerousHtml) return null
  return (
    <div
      className={classNames(
        styles.Prose,
        inverse && styles.inverse,
        color && styles.customColor,
        className
      )}
      style={{ color: color }}
      {...(dangerousHtml && {
        dangerouslySetInnerHTML: { __html: dangerousHtml }
      })}
    >
      {children}
    </div>
  )
}

Prose.propTypes = {
  children: node,
  className: string,
  dangerousHtml: string,
  inverse: bool,
  color: string
}

export default Prose
