import React from 'react'
import { bool, node } from 'prop-types'
import classNames from 'classnames'

import styles from './Panel.module.scss'

const Panel = ({ allowOverflow, children }) => (
  <div
    className={classNames(styles.Panel, allowOverflow && styles.allowOverflow)}
  >
    <div className={styles.PanelContentWrapper}>
      <div className={styles.PanelContent}>{children}</div>
    </div>
  </div>
)

Panel.propTypes = {
  allowOverflow: bool,
  children: node.isRequired
}

export default Panel
